import { OperatorDto, OperatorResponseDto } from './operator';

export type UserResponseDto = {
  id?: Nullable<number>;
  keycloakId?: Nullable<string>;
  username?: Nullable<string>;
  firstName?: Nullable<string>;
  surname?: Nullable<string>;
  email?: Nullable<string>;
  telephoneNumber?: Nullable<string>;
  driver?: Nullable<boolean>;
  validFrom?: Nullable<string>;
  validUntil?: Nullable<string>;
  operators?: Nullable<OperatorResponseDto[]>;
  roles?: string[];
};

export type UserDto = {
  id?: Nullable<number>;
  keycloakId?: Nullable<string>;
  username?: Nullable<string>;
  firstName?: Nullable<string>;
  surname?: Nullable<string>;
  fullname?: Nullable<string>;
  email?: Nullable<string>;
  telephoneNumber?: Nullable<string>;
  driver?: Nullable<boolean>;
  validFrom?: Nullable<Date>;
  validUntil?: Nullable<Date>;
  operators?: Nullable<OperatorDto[]>;
  roles?: string[];
};

export enum UserRole {
  Admin = 'admin',
  Basic = 'basic'
}
