import { Language } from 'src/state/contexts/AuthContext';
import {
  OperatorDto,
  OperatorLocalizationDto,
  OperatorResponseDto
} from '../../models/operator';

export const mapOperatorResponseToOperatorDto = (
  dto: OperatorResponseDto,
  language: Language
): OperatorDto => ({
  ...dto,
  name: dto.localization?.find(
    (x: OperatorLocalizationDto) => x.languageId === language
  )?.name
});

export const mapOperatorToOperatorResponseDto = (
  dto: OperatorDto
): OperatorResponseDto => ({
  ...dto
});
