import React, { Suspense } from 'react';
import { Location } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { PageLoader } from './components/shared/Loader/PageLoader';

export type RouteTransition = 'fade' | 'slide-right' | 'slide-left';

export const RouteTransition = {
  Fade: 'fade' as RouteTransition,
  SlideRight: 'slide-right' as RouteTransition,
  SlideLeft: 'slide-left' as RouteTransition
};

type RouteAnimationState = {
  animation?: RouteTransition;
};

type TransitionsProps = {
  children: React.ReactNode;
  location: Location;
};

export const Transitions = ({ location, children }: TransitionsProps) => {
  const { state, key } = location;
  const animation =
    (state as RouteAnimationState)?.animation ?? RouteTransition.Fade;

  return (
    <TransitionGroup
      style={{ padding: 'inherit' }}
      childFactory={child =>
        React.cloneElement(child, {
          classNames: animation
        })
      }
    >
      <CSSTransition key={key} timeout={300}>
        <Suspense fallback={<PageLoader />}>
          <div className={animation}>{children}</div>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
};
