import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

type InputFieldProps = {
  icon?: string;
  unit?: string;
  errorMessage?: string;
  displayError?: boolean;
  disabled?: boolean;
};

export const InputField = React.forwardRef<
  HTMLInputElement,
  InputFieldProps & TextFieldProps
>((props, ref) => {
  const {
    id,
    type = 'text',
    name,
    label,
    value,
    unit,
    errorMessage,
    displayError,
    onChange,
    onBlur,
    disabled = false,
    icon,
    ...rest
  } = props;

  return (
    <Input
      id={id}
      name={name}
      type={type}
      ref={ref}
      disabled={disabled}
      value={value === null || value === undefined ? '' : value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      error={displayError && errorMessage !== undefined}
      helperText={displayError && errorMessage}
      variant="outlined"
      autoComplete="off"
      InputProps={{
        ...(icon || unit
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  {icon && <Adornment className={`${icon} fa-lg`} />}
                  {unit && <Adornment>{unit}</Adornment>}
                </InputAdornment>
              )
            }
          : {})
      }}
      {...rest}
    />
  );
});

const Input = styled(TextField)`
  width: 100%;
`;

const Adornment = styled.i`
  color: #9098a9;
`;
