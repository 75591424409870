import { Language } from 'src/state/contexts/AuthContext';
import { UserDto, UserResponseDto } from '../../models/user';
import { mapOperatorResponseToOperatorDto } from '../operators/operator.map';

export const mapUserResponseToUserDto = (
  dto: UserResponseDto,
  language: Language
): UserDto => ({
  ...dto,
  fullname: dto.firstName + ' ' + dto.surname,
  validFrom: dto.validFrom ? new Date(dto.validFrom) : null,
  validUntil: dto.validUntil ? new Date(dto.validUntil) : null,
  operators: dto.operators?.map(o =>
    mapOperatorResponseToOperatorDto(o, language)
  )
});

export const mapUserToUserResponseDto = (dto: UserDto): UserResponseDto => ({
  ...dto,
  validFrom: dto.validFrom?.toISOString(),
  validUntil: dto.validUntil?.toISOString()
});
