export const Colors = {
  Background: '#fdfdfd',
  HeaderBackground: '#fdfdfd',
  SidebarBackground: '#e7e7e7',
  PrimaryDarkest: '#0b0d0e',
  PrimaryDarker: '#171a1c',
  PrimaryRGB: '33, 37, 41',
  Primary: '#212529',
  Primary80: '#54585a',
  Primary60: '#848587',
  Primary40: '#aeafb0',
  Primary20: '#d7d8d8',
  Primary8: '#f0f0f0',
  SecondaryRGB: '16, 124, 124',
  Secondary: '#107c7c',
  Secondary20: '#cddddc',
  Success: '#2ecc71',
  Danger: '#e74c3c',
  White: '#ffffff'
};
