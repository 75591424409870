import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { PageLoader } from 'src/components/shared/Loader/PageLoader';
import { UserApi } from 'src/data/api/users/users.api';
import { UserDto, UserRole } from 'src/data/models/user';
import { Storage } from 'src/utils/storage';
import {
  clearTokens,
  initializeKeycloak,
  keycloak,
  setTokens
} from '../../components/pages/Auth/keycloak.config';
import { AuthorizationError } from '../../components/pages/Error/AuthorizationError';
import { LoginError } from '../../components/pages/Error/LoginError';

export enum Language {
  Norwegian = 'no',
  English = 'en'
}

export const LANGUAGE_KEY = 'language';

export type AuthContextProps = {
  user: UserDto;
  isAuthenticated: boolean;
  language: Language;
  signin: () => void;
  signout: () => void;
  setCurrentLanguage: (language: Language) => void;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const isUserAdmin = (user: UserDto) => {
  return user?.roles?.some(r => r === UserRole.Admin);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [user, setUser] = useState<UserDto>({} as UserDto);
  const [language, setLanguage] = useState<Language>(Language.English);

  useEffect(() => {
    const setCurrentUser = async () => {
      const currentUser = await UserApi.current();
      if (currentUser) {
        setUser(currentUser);
      }
      setIsAuthenticating(false);
      setLanguage(Storage.getItem(LANGUAGE_KEY) ?? Language.English);
    };

    setIsAuthenticating(true);
    initializeKeycloak(isAuthenticated => {
      if (isAuthenticated) {
        setTokens(keycloak.token!, keycloak.refreshToken!);
        setCurrentUser();
      }
    });
  }, []);

  const signout = () => {
    clearTokens();
    keycloak.logout();
  };

  const signin = () => {
    keycloak.login();
  };

  const setCurrentLanguage = (language: Language) => {
    Storage.setItem(LANGUAGE_KEY, language);
    setLanguage(language);
  };

  const isAuthenticated = !!user?.id;
  const hasRoles = user?.roles ? user?.roles?.length > 0 : false;

  return (
    <AuthContext.Provider
      value={{
        user: user,
        isAuthenticated: isAuthenticated,
        language: language,
        signin: signin,
        signout: signout,
        setCurrentLanguage: setCurrentLanguage
      }}
    >
      {isAuthenticating && <PageLoader global />}
      {!isAuthenticated && !isAuthenticating && <LoginError />}
      {isAuthenticated && !hasRoles && <AuthorizationError />}
      {isAuthenticated && hasRoles && children}
    </AuthContext.Provider>
  );
};
