import i18n from 'i18next';

export const isRequiredMsg = (fieldName: string): string =>
  `${fieldName} ${i18n.t('validation.required')}`;

export const notValidEmailMsg = (email: string): string =>
  `${email} ${i18n.t('validation.notValidEmail')}`;

export const minLengthMsg = (length: number, fieldName: string): string =>
  `${fieldName} ${i18n.t('validation.minLengthMessage')} ${length} ${i18n.t(
    'validation.characters'
  )}`;

export const maxLengthMsg = (length: number, fieldName: string): string =>
  `${fieldName} ${i18n.t('validation.maxLengthMessage')} ${length} ${i18n.t(
    'validation.characters'
  )}`;

export const notValidPasswordMsg = (fieldName: string): string =>
  `${fieldName} ${i18n.t('validation.notValidPassword')}`;
