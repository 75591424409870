import { Box, Drawer, Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'src/assets/images/logos/unibuss_logo.svg';
import { Icon } from 'src/components/shared/Icon/Icon';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { useAuth } from '../../../state/contexts/AuthContext';
import { ProfileForm } from '../Users/ProfileForm';
import {
  Hamburger,
  HeaderContainer,
  HeaderLeftContainer,
  HeaderRightContainer,
  Logo,
  NavButton,
  Title
} from './Header.styles';

type HeaderProps = {
  onToggleSidebar: () => void;
  isSidebarOpen: boolean;
};

export const Header = ({ onToggleSidebar, isSidebarOpen }: HeaderProps) => {
  const [accountMenuOpen, setAccountMenuOpen] = useState<boolean>(false);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const { user, signout } = useAuth();

  const menuAnchorRef = useRef<HTMLButtonElement>(null);

  const onSignout = () => {
    signout();
  };

  const onAccountMenuOpen = () => {
    setAccountMenuOpen(true);
  };

  const onAccountMenuClose = () => {
    setAccountMenuOpen(false);
  };

  const onProfileOpen = () => {
    setProfileOpen(true);
  };

  const onProfileClose = () => {
    setProfileOpen(false);
  };

  return (
    <>
      <HeaderContainer>
        <HeaderLeftContainer isSidebarOpen={isSidebarOpen}>
          <Logo src={logo} alt="Smartbuss2 logo" />
        </HeaderLeftContainer>

        <HeaderRightContainer isSidebarOpen={isSidebarOpen}>
          <NavButton onClick={onToggleSidebar} borderRight>
            <Hamburger open={isSidebarOpen}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </Hamburger>
          </NavButton>

          <Box display="flex" alignItems="center">
            <Title>Smartbuss2 admin</Title>
          </Box>

          <NavButton
            as="button"
            className="ml-auto"
            borderLeft
            ref={menuAnchorRef}
            onClick={onAccountMenuOpen}
          >
            <Icon type="la-user" />
            <BodyType className="mx-8">{user?.email}</BodyType>
            <Icon type={'la-angle-down'} size={12} />
          </NavButton>
          <Menu
            anchorEl={menuAnchorRef.current}
            open={accountMenuOpen}
            onClose={onAccountMenuClose}
            onClick={onAccountMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                width: 180,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={onProfileOpen}>
              <Icon type="la-user" /> {t('profile.singular')}
            </MenuItem>
            <MenuItem onClick={onSignout}>
              <Icon type="la-sign-out-alt" /> {t('signout')}
            </MenuItem>
          </Menu>
        </HeaderRightContainer>
      </HeaderContainer>

      <Drawer anchor="right" open={profileOpen} onClose={onProfileClose}>
        <ProfileForm user={user} onCancel={onProfileClose} />
      </Drawer>
    </>
  );
};
