import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'src/components/shared/Button/Button';
import { PageContainer } from 'src/components/shared/Container/Container';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { useAuth } from '../../../state/contexts/AuthContext';

export const AuthorizationError = () => {
  const { t } = useTranslation();
  const { signout } = useAuth();

  return (
    <PageContainer>
      <Header2>{t('error.unauthorizedHeader')}</Header2>
      <p>{t('error.unauthorizedMessage')}</p>

      <hr />

      <SecondaryButton onClick={signout} className="mt-8">
        {t('signout')}
      </SecondaryButton>
    </PageContainer>
  );
};
