import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import flagGB from 'src/assets/images/flags/gb.png';
import flagNO from 'src/assets/images/flags/no.png';
import {
  PrimaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import { InputField } from 'src/components/shared/Forms/Input/InputField';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { UserDto } from 'src/data/models/user';
import { Language, useAuth } from 'src/state/contexts/AuthContext';
import { maxLengthMsg } from 'src/utils/validation';
import styled from 'styled-components';
import * as Yup from 'yup';

type ProfileFormProps = {
  user: Nullable<UserDto>;
  onCancel: () => void;
};

export const ProfileForm = (props: ProfileFormProps) => {
  const { onCancel } = props;

  const [user] = useState<UserDto>(props.user || ({} as UserDto));

  const { setCurrentLanguage } = useAuth();

  const { t, i18n } = useTranslation();

  const onChangeLanguage = (language: Language) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language);
    toast.success(`${t('language.changed')} ${language}`);
    onCancel();
  };

  const ProfileValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, maxLengthMsg(100, t('props.firstname')))
      .nullable(),
    surname: Yup.string()
      .max(100, maxLengthMsg(100, t('props.surname')))
      .nullable(),
    email: Yup.string()
      .max(100, maxLengthMsg(100, t('props.email')))
      .nullable()
  });

  return (
    <>
      <Formik
        initialValues={user}
        validationSchema={ProfileValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Header2 className="mb-32">{t('profile.singular')}</Header2>
                <InputField
                  name="firstName"
                  label={t('props.firstname')}
                  icon="las la-user"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errors.firstName}
                  displayError={touched.firstName}
                  disabled
                />
                <InputField
                  name="lastName"
                  label={t('props.lastname')}
                  icon="las la-user"
                  value={values.surname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errors.surname}
                  displayError={touched.surname}
                  disabled
                />
                <InputField
                  name="email"
                  label={t('props.email')}
                  icon="las la-at"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errors.email}
                  displayError={touched.email}
                  disabled
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" marginTop="2rem">
              <TertiaryButton
                onClick={onCancel}
                label={t('cancel')}
                className="mr-32"
              />
              <PrimaryButton type="submit" label={t('save.verb')} disabled />
            </Box>
          </Form>
        )}
      </Formik>
      <Languages>
        <Header3 className="mb-16">{t('language.singular')}</Header3>
        <img
          onClick={() => onChangeLanguage(Language.Norwegian)}
          src={flagNO}
          title={t('language.singular')}
          alt="Norwegian flag"
        />
        <img
          onClick={() => onChangeLanguage(Language.English)}
          src={flagGB}
          title={t('language.singular')}
          alt="English flag"
        />
      </Languages>
    </>
  );
};

const Languages = styled.section`
  position: absolute;
  bottom: 2rem;

  & img {
    cursor: pointer;
    margin-right: 1rem;
    height: 16px;
    width: 24px;
    transform: scale(1);
    transition: all 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }
`;
