import { ThemeProvider, useMediaQuery } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import ErrorBoundary from 'src/components/pages/Error/ErrorBoundary';
import { PageContainer } from 'src/components/shared/Container/Container';
import { AppRoutes } from 'src/routes';
import { SWRConfig } from 'swr';
import { theme } from './assets/theme';
import { fetcher } from './data/api-handler';
import { Notifications } from './Notifications';
import { AuthProvider } from './state/contexts/AuthContext';
import { GlobalStyle } from './App.styles';
import { Suspense, useEffect, useState } from 'react';
import { Header } from './components/pages/Navigation/Header';
import { Sidebar } from './components/pages/Navigation/Sidebar';
import { breakpoints } from './assets/_responsive-mixin';
import { PageLoader } from './components/shared/Loader/PageLoader';

import '@progress/kendo-theme-material/scss/grid/_index.scss';
import 'src/assets/styles/scss/transitions.scss';
import 'src/assets/styles/scss/flexbox.scss';
import 'src/assets/styles/scss/fonts.scss';
import 'src/assets/styles/scss/gap.scss';
import 'src/assets/styles/scss/kendo/overrides.scss';
import 'src/assets/styles/scss/kendo/variables.scss';
import 'src/assets/styles/scss/layout.scss';
import 'src/assets/styles/scss/margin.scss';
import 'src/assets/styles/scss/padding.scss';
import 'src/assets/styles/scss/utils.scss';

export const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.small})`);

  const onToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    setIsSidebarOpen(!isSmallScreen);
  }, [isSmallScreen]);

  return (
    <AuthProvider>
      <GlobalStyle />
      <Notifications />
      <SWRConfig value={{ fetcher }}>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <RecoilRoot>
                <Header
                  onToggleSidebar={onToggleSidebar}
                  isSidebarOpen={isSidebarOpen}
                />
                <Sidebar
                  onToggleSidebar={onToggleSidebar}
                  isSidebarOpen={isSidebarOpen}
                />
                <PageContainer isSidebarOpen={isSidebarOpen}>
                  <Suspense fallback={<PageLoader />}>
                    <AppRoutes />
                  </Suspense>
                </PageContainer>
              </RecoilRoot>
            </BrowserRouter>
          </ThemeProvider>
        </ErrorBoundary>
      </SWRConfig>
    </AuthProvider>
  );
};
