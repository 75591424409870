import { Toaster } from 'react-hot-toast';

export function Notifications() {
  return (
    <Toaster
      position="bottom-right"
      gutter={8}
      toastOptions={{
        duration: 5000,
        success: {
          style: {
            background: '#cddbdb',
            color: '#000'
          }
        },
        error: {
          style: {
            background: '#ffd6ca',
            color: '#000'
          }
        }
      }}
    />
  );
}
