import { Colors } from 'src/assets/styles/colors';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body {
        background: #fff;
        font-size: 1rem;
        color: ${Colors.Primary};
        width: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    button:focus {
        outline: none;
    }

    [tabindex='-1']:focus:not(:focus-visible) {
        outline: 0 !important;
    }
`;
