import styled, { css } from 'styled-components';
import { media } from 'src/assets/_responsive-mixin';
import { Sizes } from 'src/assets/styles/sizes';
import { Colors } from 'src/assets/styles/colors';

type PageContainerProps = {
  isSidebarOpen?: boolean;
};

export const PageContainer = styled.section<PageContainerProps>`
  position: relative;
  height: 100%;
  min-height: calc(100vh - ${Sizes.HeaderHeight});
  padding: 16px 128px 128px;
  margin: 0 auto;
  margin-top: ${Sizes.HeaderHeight};
  background: ${Colors.Background};
  transition: margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  ${({ isSidebarOpen }) =>
    isSidebarOpen
      ? css`
          width: calc(100% - ${Sizes.SidebarWidth});
          margin-left: ${Sizes.SidebarWidth};
        `
      : css`
          width: 100%;
          margin-left: 0;
        `}

  ${media.xlarge`
    padding: 16px 64px 128px;
	`}

  ${media.large`
    padding: 16px 64px 64px;
	`}

  ${media.small`
    margin-left: 0;
    width: 100%;
    padding: 16px;
	`}
`;
