import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/assets/styles/colors';
import styled from 'styled-components';
import { Header2 } from '../Typography/Header2';
import { dash, rotator } from './Loader';

type PageLoaderProps = {
  global?: boolean;
};

export const PageLoader = ({ global = false }: PageLoaderProps) => {
  const { t } = useTranslation();

  return (
    <Section global={global}>
      <Svg
        style={{
          width: 50,
          height: 50
        }}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Circle
          style={{
            stroke: Colors.Primary,
            strokeWidth: 6
          }}
          fill="none"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </Svg>

      <Header2 className="mt-16">{t('loading')}</Header2>
    </Section>
  );
};

const Section = styled.section<PageLoaderProps>`
  position: ${({ global }) => (global ? 'fixed' : 'absolute')};
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 0.8);
`;

const Svg = styled.svg`
  animation: ${rotator} 1.4s linear infinite;
`;

const Circle = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite;
`;
