import { createTheme } from '@mui/material/styles';
import { Colors } from './styles/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Primary,
      light: Colors.Primary20,
      dark: Colors.PrimaryDarker
    },
    secondary: {
      main: Colors.Secondary,
      light: Colors.Secondary20
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920
    }
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 4,
        style: {
          flexGrow: 1
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '16px'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: '#ddd'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '52px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '24px'
        }
      }
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 500,
          padding: 24
        }
      }
    }
  }
});
