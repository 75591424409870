import { Fragment, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Transitions } from './Transitions';

const DashboardPage = lazy(() => import('./components/pages/Dashboard/DashboardPage'));

const MainStopPage = lazy(() => import('./components/pages/Navigation/MainStopPage'));

const MainNetworksPage = lazy(() => import('./components/pages/Navigation/MainNetworkPage'));

const MainZonePage = lazy(() => import('./components/pages/Navigation/MainZonePage'));

const MainActiveNetworkPage = lazy(() => import('./components/pages/Navigation/MainActiveNetworkPage'));

const MainTravellerProfilePage = lazy(() => import('./components/pages/Navigation/MainTravellerProfilePage'));

const MainProductsPricingPage = lazy(() => import('./components/pages/Navigation/MainProductsPricingPage'));

const MainSalesChannelsPage = lazy(() => import('./components/pages/Navigation/MainSalesChannelsPage'));

const MainSalesRulesPage = lazy(() => import('./components/pages/Navigation/MainSalesRulesPage'));

const MainShiftPage = lazy(() => import('./components/pages/Navigation/MainShiftPage'));

const MainSalesEquipmentPage = lazy(() => import('./components/pages/Navigation/MainSalesEquipmentPage'));

const MainCustomerPage = lazy(() => import('./components/pages/Navigation/MainCustomerPage'));

const MainVehiclePlanningPage = lazy(() => import('./components/pages/Navigation/MainVehiclePlanningPage'));

const MainUserAccessPage = lazy(() => import('./components/pages/Navigation/MainUserAccessPage'));

const TicketsPage = lazy(() => import('./components/pages/FareManagement/Tickets/TicketsPage'));

const VehiclesPage = lazy(() => import('./components/pages/Vehicles/Vehicles/VehiclesPage'));
const VehicleTypesPage = lazy(() => import('./components/pages/Vehicles/VehicleTypes/VehicleTypesPage'));

const UsersPage = lazy(() => import('./components/pages/Users/UsersPage'));

const NotFound = lazy(() => import('src/components/pages/Error/NotFound'));
const Unauthorized = lazy(() => import('src/components/pages/Error/Unauthorized'));

const CustomersPage = lazy(() => import('./components/pages/Customers/CustomersPage'));
const SeatPricingDefinitionsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/SeatPricingDefinitions/SeatPricingDefinitionsPage'));
const SeatPricingDefinitionEditPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/SeatPricingDefinitions/SeatPricingDefinition/SeatPricingDefinitionEditPage'));
const ZoneMatrixPriceDefinitionsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/ZoneMatrixPriceDefinitions/ZoneMatrixPriceDefinitionsPage'));
const ZoneMatrixPriceDefinitionEditPage = lazy(
  () => import('./components/pages/FareManagement/ProductsPricing/ZoneMatrixPriceDefinitions/ZoneMatrixPriceDefinition/ZoneMatrixPriceDefinitionEditPage')
);
const ZoneMatrixPriceAssignmentsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/ZoneMatrixPriceAssignments/ZoneMatrixPriceAssignmentsPage'));
const ProductsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/Products/ProductsPage'));
const ProductEditPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/Products/Product/ProductEditPage'));
const SalesChannelsPage = lazy(() => import('./components/pages/FareManagement/SalesChannels/SalesChannelsPage'));
const PaymentProcessorsPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/PaymentProcessors/PaymentProcessorsPage'));
const PaymentTerminalsPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/PaymentTerminals/PaymentTerminalsPage'));
const PaymentTerminalTypesPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/PaymentTerminalTypes/PaymentTerminalTypesPage'));
const SalesEquipmentDevicesPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/SalesEquipmentDevices/SalesEquipmentDevicesPage'));
const SalesEquipmentDeviceTypesPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/SalesEquipmentDeviceTypes/SalesEquipmentDeviceTypesPage'));
const OpenTicketValiditiesPage = lazy(() => import('./components/pages/FareManagement/SalesRules/OpenTicketValidities/OpenTicketValiditiesPage'));
const OverbookingsPage = lazy(() => import('./components/pages/FareManagement/SalesRules/Overbookings/OverbookingsPage'));
const SalePeriodsPage = lazy(() => import('./components/pages/FareManagement/SalesRules/SalePeriods/SalePeriodsPage'));
const ShiftsPage = lazy(() => import('./components/pages/FareManagement/Shifts/ShiftsPage'));
const TravellerProfilesPage = lazy(() => import('./components/pages/FareManagement/TravellerProfiles/TravellerProfilesPage'));
const ActiveJourneyPatternsPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveJourneyPatterns/ActiveJourneyPatternsPage'));
const ActiveJourneyPatternServiceJourneysPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveJourneyPatternServiceJourneys/ActiveJourneyPatternServiceJourneysPage'));
const ActiveLinesPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveLines/ActiveLinesPage'));
const ActiveNetworkValidationPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveNetworkValidation/ActiveNetworkValidationPage'));
const ActiveRoutesPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveRoutes/ActiveRoutesPage'));
const ActiveStopPointInJourneyPatternsPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveStopPointsInJourneyPatterns/ActiveStopPointInJourneyPatternsPage'));
const DatasetsPage = lazy(() => import('./components/pages/TransportNetwork/Networks/Datasets/DatasetsPage'));
const JourneyPatternServiceJourneysPage = lazy(() => import('./components/pages/TransportNetwork/Networks/JourneyPatternServiceJourneys/JourneyPatternServiceJourneysPage'));
const LinesRoutesJourneyPatternsPage = lazy(() => import('./components/pages/TransportNetwork/Networks/LinesRoutesJourneyPatterns/LinesRoutesJourneyPatternsPage'));
const OperatorsPage = lazy(() => import('./components/pages/TransportNetwork/Networks/Operators/OperatorsPage'));
const StopPointInJourneyPatternsPage = lazy(() => import('./components/pages/TransportNetwork/Networks/StopPointsInJourneyPatterns/StopPointInJourneyPatternsPage'));
const QuaysPage = lazy(() => import('./components/pages/TransportNetwork/Quays/QuaysPage'));
const QuaysSyncPage = lazy(() => import('./components/pages/TransportNetwork/Quays/QuaysSyncPage'));
const ZonesPage = lazy(() => import('./components/pages/TransportNetwork/Zones/ZonesPage'));
const ZoneEditPage = lazy(() => import('./components/pages/TransportNetwork/Zones/Zone/ZoneEditPage'));
const ZoneValidationPage = lazy(() => import('./components/pages/TransportNetwork/Zones/ZoneValidationPage'));
const VehiclePlansServiceJourneysPage = lazy(() => import('./components/pages/Vehicles/VehiclePlanning/VehiclePlanningServiceJourneys/VehiclePlansServiceJourneysPage'));

export const Paths = {
  base: '/',
  stops: {
    base: '/stops',
    manage: '/stops/manage',
    synchronize: '/stops/synchronize'
  },
  networks: {
    base: '/networks',
    datasets: '/networks/datasets',
    operators: '/networks/operators',
    lines: '/networks/linesroutesjourneypatterns',
    travelPatterns: '/networks/travelpatterns',
    stopPointInJourneyPatterns: '/networks/stoppointinjourneypatterns',
    journeyPatternServiceJourneys: '/networks/journeypatternservicejourneys',
    timetables: '/networks/timetables',
    operations: '/networks/operations'
  },
  zones: {
    base: '/zones',
    manage: '/zones/manage',
    validation: '/zones/validation'
  },
  activenetwork: {
    base: '/activenetwork',
    activeLines: '/activenetwork/activelines',
    activeRoutes: '/activenetwork/activeroutes',
    activeJourneyPatterns: '/activenetwork/activejourneypatterns',
    activeStopPointInJourneyPatterns: '/activenetwork/activestoppointinjourneypatterns',
    activeJourneyPatternServiceJourneys: '/activenetwork/activejourneypatternservicejourneys',
    timetables: '/activenetwork/timetables',
    validation: '/activenetwork/validation'
  },
  travellerProfiles: {
    base: '/travellerprofiles',
    manage: '/travellerprofiles/manage'
  },
  productsPricing: {
    base: '/productspricing',
    zonePricingDefinitions: '/productspricing/zonepricingdefinitions',
    zonePricingAssignments: '/productspricing/zonepricingassignments',
    seatPricingDefinitions: '/productspricing/seatpricingdefinitions',
    seatPricingAssignments: '/productspricing/seatpricingassignments',
    productsAndServices: '/productspricing/productsandservices',
    validation: '/productspricing/validation'
  },
  salesChannels: {
    base: '/saleschannels',
    manage: '/saleschannels/manage'
  },
  salesRules: {
    base: '/salesrules',
    overbooking: '/salesrules/overbooking',
    salePeriod: '/salesrules/saleperiod',
    saleEnd: '/salesrules/saleend',
    openTicketValidity: '/salesrules/openticketvalidity',
    adultBasedPricing: '/salesrules/adultbasedpricing'
  },
  tickets: {
    base: '/tickets',
    sell: '/tickets/sell',
    departures: '/tickets/departures',
    ticketLayout: '/tickets/ticketlayout'
  },
  shifts: {
    base: '/shifts',
    reports: '/shifts/reports'
  },
  salesEquipment: {
    base: '/salesequipment',
    salesEquipmentDevices: '/salesequipment/salesequipmentdevices',
    salesEquipmentDeviceTypes: '/salesequipment/salesequipmentdevicetypes',
    paymentTerminals: '/salesequipment/paymentterminals',
    paymentTerminalTypes: '/salesequipment/paymentterminaltypes',
    paymentProcessors: '/salesequipment/paymentprocessors'
  },
  customers: {
    base: '/customers',
    manage: '/customers/manage'
  },
  vehicles: {
    base: '/vehicles'
  },
  vehicleTypes: {
    base: '/vehicletypes'
  },
  vehiclePlanning: {
    base: '/vehicleplanning',
    journeyPatterns: '/vehicleplanning/journeypatterns',
    serviceJourneys: '/vehicleplanning/servicejourneys',
    validation: '/vehicleplanning/validation'
  },
  reports: {
    financial: '/reports/financial',
    passengers: '/reports/passengers'
  },
  support: {
    base: '/support'
  },
  users: {
    base: '/users',
    access: {
      base: '/users/access',
      rights: '/users/access/rights',
      roles: '/users/access/roles'
    }
  },
  settings: {
    base: '/settings',
    languages: '/settings/languages',
    visual: '/settings/visual',
    other: '/settings/other'
  },
  notFound: '/notfound',
  unauthorized: '/unauthorized'
};

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Transitions location={location}>
      <Routes location={location}>
        <Route path={Paths.base} element={<DashboardPage />} />

        <Route path={Paths.stops.base} element={<MainStopPage />}>
          <Route path={Paths.stops.manage} element={<QuaysPage />} />
          <Route path={Paths.stops.synchronize} element={<QuaysSyncPage />} />
        </Route>

        <Route path={Paths.networks.base} element={<MainNetworksPage />}>
          <Route path={Paths.networks.datasets} element={<DatasetsPage />} />
          <Route path={Paths.networks.operators} element={<OperatorsPage />} />
          <Route path={Paths.networks.lines} element={<LinesRoutesJourneyPatternsPage />} />
          <Route path={Paths.networks.stopPointInJourneyPatterns} element={<StopPointInJourneyPatternsPage />} />
          <Route path={Paths.networks.journeyPatternServiceJourneys} element={<JourneyPatternServiceJourneysPage />} />
          <Route path={Paths.networks.timetables} element={<Fragment />} />
          <Route path={Paths.networks.operations} element={<Fragment />} />
        </Route>

        <Route path={`${Paths.zones.manage}/create`} element={<ZoneEditPage />} />
        <Route path={`${Paths.zones.manage}/:id`} element={<ZoneEditPage />} />

        <Route path={Paths.zones.base} element={<MainZonePage />}>
          <Route path={Paths.zones.manage} element={<ZonesPage />} />
          <Route path={Paths.zones.validation} element={<ZoneValidationPage />} />
        </Route>

        <Route path={Paths.activenetwork.base} element={<MainActiveNetworkPage />}>
          <Route path={Paths.activenetwork.activeLines} element={<ActiveLinesPage />} />
          <Route path={Paths.activenetwork.activeRoutes} element={<ActiveRoutesPage />} />
          <Route path={Paths.activenetwork.activeJourneyPatterns} element={<ActiveJourneyPatternsPage />} />
          <Route path={Paths.activenetwork.activeStopPointInJourneyPatterns} element={<ActiveStopPointInJourneyPatternsPage />} />
          <Route path={Paths.activenetwork.activeJourneyPatternServiceJourneys} element={<ActiveJourneyPatternServiceJourneysPage />} />
          <Route path={Paths.activenetwork.timetables} element={<Fragment />} />
          <Route path={Paths.activenetwork.validation} element={<ActiveNetworkValidationPage />} />
        </Route>

        <Route path={Paths.travellerProfiles.base} element={<MainTravellerProfilePage />}>
          <Route path={Paths.travellerProfiles.manage} element={<TravellerProfilesPage />} />
        </Route>

        <Route path={`${Paths.productsPricing.zonePricingDefinitions}/create`} element={<ZoneMatrixPriceDefinitionEditPage />} />
        <Route path={`${Paths.productsPricing.zonePricingDefinitions}/:id`} element={<ZoneMatrixPriceDefinitionEditPage />} />
        <Route path={`${Paths.productsPricing.seatPricingDefinitions}/create`} element={<SeatPricingDefinitionEditPage />} />
        <Route path={`${Paths.productsPricing.seatPricingDefinitions}/:id`} element={<SeatPricingDefinitionEditPage />} />
        <Route path={`${Paths.productsPricing.productsAndServices}/create`} element={<ProductEditPage />} />
        <Route path={`${Paths.productsPricing.productsAndServices}/:id`} element={<ProductEditPage />} />

        <Route path={Paths.productsPricing.base} element={<MainProductsPricingPage />}>
          <Route path={Paths.productsPricing.zonePricingDefinitions} element={<ZoneMatrixPriceDefinitionsPage />} />
          <Route path={Paths.productsPricing.zonePricingAssignments} element={<ZoneMatrixPriceAssignmentsPage />} />
          <Route path={Paths.productsPricing.seatPricingDefinitions} element={<SeatPricingDefinitionsPage />} />
          <Route path={Paths.productsPricing.seatPricingAssignments} element={<Fragment />} />
          <Route path={Paths.productsPricing.productsAndServices} element={<ProductsPage />} />
          <Route path={Paths.productsPricing.validation} element={<Fragment />} />
        </Route>

        <Route path={Paths.salesChannels.base} element={<MainSalesChannelsPage />}>
          <Route path={Paths.salesChannels.manage} element={<SalesChannelsPage />} />
        </Route>

        <Route path={Paths.salesRules.base} element={<MainSalesRulesPage />}>
          <Route path={Paths.salesRules.overbooking} element={<OverbookingsPage />} />
          <Route path={Paths.salesRules.salePeriod} element={<SalePeriodsPage />} />
          <Route path={Paths.salesRules.saleEnd} element={<Fragment />} />
          <Route path={Paths.salesRules.openTicketValidity} element={<OpenTicketValiditiesPage />} />
          <Route path={Paths.salesRules.adultBasedPricing} element={<Fragment />} />
        </Route>

        <Route path={Paths.tickets.base} element={<TicketsPage />} />
        <Route path={Paths.tickets.sell} element={<Fragment />} />
        <Route path={Paths.tickets.departures} element={<Fragment />} />
        <Route path={Paths.tickets.ticketLayout} element={<Fragment />} />

        <Route path={Paths.shifts.base} element={<MainShiftPage />}>
          <Route path={Paths.shifts.reports} element={<ShiftsPage />} />
        </Route>

        <Route path={Paths.salesEquipment.base} element={<MainSalesEquipmentPage />}>
          <Route path={Paths.salesEquipment.salesEquipmentDevices} element={<SalesEquipmentDevicesPage />} />
          <Route path={Paths.salesEquipment.salesEquipmentDeviceTypes} element={<SalesEquipmentDeviceTypesPage />} />
          <Route path={Paths.salesEquipment.paymentTerminals} element={<PaymentTerminalsPage />} />
          <Route path={Paths.salesEquipment.paymentTerminalTypes} element={<PaymentTerminalTypesPage />} />
          <Route path={Paths.salesEquipment.paymentProcessors} element={<PaymentProcessorsPage />} />
        </Route>

        <Route path={Paths.customers.base} element={<MainCustomerPage />}>
          <Route path={Paths.customers.manage} element={<CustomersPage />} />
        </Route>

        <Route path={Paths.vehicles.base} element={<VehiclesPage />} />
        <Route path={Paths.vehicleTypes.base} element={<VehicleTypesPage />} />
        <Route path={Paths.vehiclePlanning.base} element={<MainVehiclePlanningPage />}>
          <Route path={Paths.vehiclePlanning.journeyPatterns} element={<VehiclePlansServiceJourneysPage />} />
          <Route path={Paths.vehiclePlanning.serviceJourneys} element={<Fragment />} />
          <Route path={Paths.vehiclePlanning.validation} element={<Fragment />} />
        </Route>

        <Route path={Paths.users.base} element={<UsersPage />} />
        <Route path={Paths.users.access.base} element={<MainUserAccessPage />}>
          <Route path={Paths.users.access.rights} element={<Fragment />} />
          <Route path={Paths.users.access.roles} element={<Fragment />} />
        </Route>

        <Route path={Paths.settings.languages} element={<Fragment />} />
        <Route path={Paths.settings.visual} element={<Fragment />} />
        <Route path={Paths.settings.other} element={<Fragment />} />

        <Route path={Paths.notFound} element={<NotFound />} />
        <Route path={Paths.unauthorized} element={<Unauthorized />} />
      </Routes>
    </Transitions>
  );
};
