import { Colors } from 'src/assets/styles/colors';
import { Sizes } from 'src/assets/styles/sizes';
import { media } from 'src/assets/_responsive-mixin';
import styled, { css } from 'styled-components';

export const SidebarContainer = styled.aside`
  position: fixed;
  left: 0;
  bottom: 0;
  width: ${Sizes.SidebarWidth};
  height: calc(100% - ${Sizes.HeaderHeight});
  background: ${Colors.Primary};
  overflow: auto;
  z-index: 99;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translateX(-${Sizes.SidebarWidth});

  &.open {
    transform: translateX(0px);
  }

  ${media.xsmall`
    width: 100%;
    transform: translateX(-100%) !important;
    
    &.open {
      transform: translateX(0px) !important;
    }    
  `}
`;

export const SidebarNav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const SidebarItemList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 0.5rem;
`;

type SidebarItemProps = {
  active: boolean;
  disabled?: boolean;
};

export const SidebarItem = styled.li<SidebarItemProps>`
  position: relative;
  height: 3rem;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  border-radius: 8px;
  transition: all 200ms ease-in-out;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;

  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${Colors.White};

  &:hover {
    background: ${Colors.Primary80};
  }

  ${({ active }) =>
    active &&
    css`
      background: ${Colors.Primary60} !important;
    `};
`;

export const SidebarItemChildList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0rem;
  transition: height 300ms ease-in-out;
  overflow: hidden;
  max-height: 100%;
`;

export const SidebarItemChild = styled(SidebarItem)`
  padding-left: 3rem;
`;

export const Logo = styled.img`
  max-width: 6rem;
  align-self: center;
  margin-top: auto;
`;
