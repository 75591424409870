import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { initializeI18n } from './i18n';
import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('smartbuss2-container')!);

initializeI18n();

root.render(<App />);

serviceWorker.unregister();
